// ==========================================================================
// Useful Links
// ==========================================================================

.useful-link {
  display: block;
  margin-bottom: 1.5rem;

  .thumbnail {
    margin-bottom: .5rem;
  }

  small {
    display: block;
    font-style: italic;
    color: #777;
  }
}

.link-title {
  display: block;
  overflow: hidden;
  height: 1rem;
  padding: 0;
  line-height: 1;
}
