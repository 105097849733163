// ==========================================================================
// Entries Navigation
// ==========================================================================

.entry-nav {
  position: absolute;
  top: .5rem;
  left: 0;
  z-index: 100;
  overflow: hidden;
  width: 100%;
  height: 3.75rem;

  .case-study & {
    top: 20rem;
  }

  .headline-content & {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
  }
}

.entry-nav-links {
  margin: 0;
  padding: 0;
  list-style: none;

  @include has('item') {
    position: absolute;
    top: 0;

    @include variant('next') {
      right: 0;
    }

    @include variant('prev') {
      left: 0;
    }
  }

  @include has('link') {
    position: relative;
    display: block;
    width: 160px;
    padding: 1rem 2rem;
    background: $brand-color-b;
    color: $white;
    transition: .5s;

    .entry-nav-links__item--prev & {
      left: -90%;
      text-align: right;

      @media #{$large-up} {
        left: -80%;
      }

      &:hover,
      &:focus {
        left: 0;
      }
    }

    .entry-nav-links__item--next & {
      right: -90%;

      @media #{$large-up} {
        right: -80%;
      }

      &:hover,
      &:focus {
        right: 0;
      }
    }
  }
}
