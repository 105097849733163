// --------------------------------------------------------------------------
// Browser Upgrade Notice
// --------------------------------------------------------------------------

.browserupgrade {
  margin: 0;
  padding: 1rem 2rem;
  background-color: $brand-color-b;
  font-size: .75rem;
  letter-spacing: 1.5px;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;

  &,
  a,
  strong {
    color: $white;
  }

  a {
    text-decoration: underline;
  }
}
