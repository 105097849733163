// ==========================================================================
// Producers
// ==========================================================================

.producers {
  @extend .clearfix;
}

.producer {
  @media #{$medium-up} {
    float: left;
    width: 47%;
    margin-right: 6%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @include has('name') {
    font-size: 1.5rem;
    font-weight: 500;

    @media #{$medium-up} {
      margin-top: 0;
    }
  }
}
