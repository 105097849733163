// --------------------------------------------------------------------------
// Typography
// --------------------------------------------------------------------------

body,
input,
textarea {
  color: tint($brand-color-a, 40%);
}

p {
  strong,
  b,
  em {
    color: tint($brand-color-a, 20%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: tint($brand-color-a, 10%);
  line-height: 1.2;

  .section--plain-page &:first-child {
    margin-top: 0;
  }
}

a {
  color: $brand-color-c;
  outline: none;
}

// --------------------------------------------------------------------------
// Measure
// --------------------------------------------------------------------------

.measure {
  max-width: 42rem;
  margin: 0 auto;

  @include variant('wide') {
    max-width: 50rem;
  }
}
