// ==========================================================================
// Headlines
// ==========================================================================

.headlines-grid {
  @include clearfix;

  @include has('item') {
    @media #{$medium-up} {
      float: left;
    }

    @media #{$medium-only} {
      width: 49.5%;
      margin-right: 1%;
      margin-bottom: 1%;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media #{$large-up} {
      width: 32.66666%;
      margin-right: 1%;
      margin-bottom: 1%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.headlines {
  @include clearfix;
  margin-top: .25rem;

  .owl-wrapper {
    .owl-item {
      padding-right: .125rem;
      padding-left: .125rem;
    }
  }
}

.headline {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: .25rem;
  background-color: $brand-color-a;

  > img {
    display: block;
    width: 100%;
  }

  @media #{$medium-up} {
    margin-bottom: 0;
  }

  @include has('heading') {
    position: absolute;
    bottom: -10rem;
    margin: 0 1rem;
    padding: .25rem .5rem;
    background-color: $brand-color-b;
    font-size: .75rem;
    font-weight: 500;
    font-family: $font-family-headings;
    color: $white;
    letter-spacing: .125rem;
    text-transform: uppercase;
    transition: .5s;

    .headline:hover & {
      bottom: 1rem;
    }
  }

  // @include has('overlay') {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   @include gradient(transparent, shade($brand-color-a, 25%));
  //   opacity: 0;
  //   transition: .5s;

  //   .headline:hover & {
  //     opacity: .75;
  //   }
  // }
}

// --------------------------------------------------------------------------
// Owl Carousel (TODO: Move to it's own file)
// --------------------------------------------------------------------------

.owl-carousel .owl-wrapper {
  @include clearfix;
}

/* display none until init */
.owl-carousel {
  position: relative;
  display: none;
  width: 100%;
  -ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper {
  position: relative;
  display: none;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-wrapper-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
  transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item {
  float: left;

  .matrix-gallery-slideshow & a {
    display: block;
    cursor: zoom-in;
  }
}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer;
}

.owl-controls {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing {
  // cursor:url(grabbing.png) 8 8, move;
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
  backface-visibility: hidden;
  transform: translate3d(0,0,0);
}

.owl-theme .owl-controls .owl-pagination {
  text-align: center;
}

.owl-theme .owl-controls .owl-page span {
  width: 10px;
  height: 10px;
  margin: .125rem;
}

.owl-theme .owl-controls {
  margin-top: .175rem;
  text-align: center;
}

/* Styling Next and Prev buttons */
.owl-theme .owl-controls .owl-buttons div {
  display: inline-block;
  margin: 5px;
  padding: 3px 10px;
  border-radius: 30px;
  background: #869791;
  opacity: .5;
  font-size: 12px;
  color: $white;
  zoom: 1;
  filter: Alpha(Opacity=50);/*IE7 fix*/
}

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover {
  opacity: 1;
  text-decoration: none;
  filter: Alpha(Opacity=100); /*IE7 fix*/
}

/* Styling Pagination */
.owl-theme .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  *display: inline; /*IE7 life-saver */
}

.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 8px;
  height: 8px;
  margin: 0 5px;
  border: 1px solid $brand-color-c;
  border-radius: 20px;
  background: none;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  background-color: $brand-color-c;
}

/* If PaginationNumbers is true */
.owl-theme .owl-controls .owl-page span.owl-numbers {
  width: auto;
  height: auto;
  padding: 2px 10px;
  border-radius: 30px;
  font-size: 12px;
  color: $white;
}

