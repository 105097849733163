@media #{$small-only} {
  .hidden-sm {
    display: none !important;
  }
}

@media #{$large-up} {
  .visible-md {
    display: none !important;
  }
}

@media #{$medium-up} {
  .visible-sm {
    display: none !important;
  }
}

@media #{$screen} and (min-width: 1280px) {
  .visible-header {
    display: none !important;
  }
}
