// ==========================================================================
// Category Navigation
// ==========================================================================

.category-nav {
  margin-top: 1.5rem;

  > ul {
    @extend .list-inline;
    font-size: .75rem;
    font-weight: 700;
    letter-spacing: .075rem;
    text-transform: uppercase;

    > li {
      margin-bottom: .5rem;
      padding-right: .25rem;

      @media #{$medium-up} {
        margin-bottom: 0;
      }

      > a,
      > span {
        display: block;
        padding: .3rem .6rem;
        color: tint($brand-color-a, 50%);
        line-height: 1;
      }

      > a {
        &:hover {
          background-color: ($brand-color-d);
          color: tint($brand-color-a, 25%);
          transition: .3s;
        }
      }

      > span {
        opacity: .25;
      }

      &.active a {
        background-color: $brand-color-b;
        color: $white;
      }
    }
  }
}

.category-nav-shaded > ul > li > a {
  background-color: tint($brand-color-d, 50%);
}
