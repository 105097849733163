// ==========================================================================
// Generic Page Styles
// ==========================================================================

.page-header {
  padding: 1rem 0 0;
  text-align: center;

  @media #{$medium-up} {
    padding: 1rem 0;
  }

  @media #{$large-up} {
    margin-top: -9.25rem;
    padding-top: 9.25rem;
    padding-bottom: 0;
  }

  @include variant('deep') {
    padding-bottom: 2rem;

    @media #{$medium-up} {
      padding-bottom: 3rem;
    }
  }
}

.page-heading {
  margin: 0;
  font-size: 2.25rem;

  @media #{$medium-up} {
    font-size: 2.5rem;
  }

  @media #{$large-up} {
    font-size: 3rem;
  }

  @include variant('light') {
    font-weight: 300;
  }
}
