// ==========================================================================
// Base Styles
// ==========================================================================

body {
  position: relative; // Safari overflow scroll fix
  -webkit-text-size-adjust: none;
}

/* --------------------------------------------------------------------------
 * Containers
 * -------------------------------------------------------------------------- */

.container {
  margin: 0 auto;
  padding: 0 1.5rem;

  @media #{$medium-up} {
    padding: 0 3rem;
  }

  @media #{$large-up} {
    max-width: 80rem;
  }

  .site-header & {
    @include clearfix;
  }
}

/* --------------------------------------------------------------------------
 * Images
 * -------------------------------------------------------------------------- */

.thumbnail {
  > img {
    display: block;
    width: 100%;
  }
}
