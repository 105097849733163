// ==========================================================================
// Service Page
// ==========================================================================

.service-header {
  @extend %gradient;
  position: relative;
  padding-top: 2rem;
  text-align: center;

  @include variant('production') {
    // margin-top: -10rem;
    padding: 16rem 0;
    background: url('../img/temp/green-screen.jpg');
    background-position: center;
    background-size: cover;
  }

}

.service-heading {
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  font-size: 1.75rem;
  line-height: 1.1;

  @media #{$medium-up} {
    font-size: 2rem;
  }

  @media #{$large-up} {
    max-width: 49rem;
    font-size: 3rem;
  }
}

.service-description {
  max-width: 33rem;
  margin-right: auto;
  margin-left: auto;

  p {
    @media #{$medium-up} {
      font-size: 1.125rem;
    }
  }
}

.service-hero-image {
  margin-top: 2rem;
  margin-bottom: 0;

  @media #{$small-only} {
    overflow: hidden;
    margin-right: -1.5rem;
    margin-left: -1.5rem;

    > img {
      width: 150%;
      margin: 0 auto;
      margin-right: -25%;
      margin-left: -25%;
    }
  }

  @media #{$medium-up} {
    margin-right: -2rem;
    margin-left: -2rem;
  }

  @media #{$large-up} {
    margin-right: 3rem;
    margin-left: 3rem;
  }
}

.arrow-down {
  position: absolute;
  bottom: -.125rem;
  left: 0;
  width: 100%;
  height: 2rem;
  background: url('../img/services/curve-w-arrow.svg') no-repeat top center;
  background-size: 50%;

  @media #{$medium-up} {
    height: 3rem;
    background-size: auto;
  }
}

.featured-quote {
  position: relative;

  p {
    font-size: 1.5rem;
    font-weight: 300;
    font-style: italic;
    color: $brand-color-c;
    line-height: 1.5;

    &:first-letter {
      margin-left: -.6rem;
    }
  }

  footer {
    font-size: .875rem;
    color: $white;
    text-transform: uppercase;
  }
}
