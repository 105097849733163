// ==========================================================================
// Club Getup: Grid
// ==========================================================================

.container {
  margin-right: auto;
  margin-left: auto;
}

.container,
.container-fluid {
  padding-right: ($grid-gutter);
  padding-left: ($grid-gutter);
}

.row {
  @include clearfix;
  margin-right: -($grid-gutter / 2);
  margin-left: -($grid-gutter / 2);
}

[class*='col-'] {
  float: left;
  width: 100%;
  padding-right: ($grid-gutter / 2);
  padding-left: ($grid-gutter / 2);
}

.row-gutterless {
  margin-right: 0;
  margin-left: 0;

  [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

@media #{$small-up} {
  @include grid-columns('col-sm', $grid-columns);
  @include grid-columns-offset('col-offset-sm', $grid-columns);
}

@media #{$medium-up} {
  @include container($medium-max);
  @include grid-columns('col-md', $grid-columns);
  @include grid-columns-offset('col-offset-md', $grid-columns);
}

@media #{$large-up} {
  @include container($large-max);
  @include grid-columns('col-lg', $grid-columns);
  @include grid-columns-offset('col-offset-lg', $grid-columns);
}

@media #{$xlarge-up} {
  @include container($xlarge-max);
  @include grid-columns('col-xl', $grid-columns);
  @include grid-columns-offset('col-xl', $grid-columns);
}

@media #{$xxlarge-up} {
  @include container($xxlarge-min);
  @include grid-columns('col-xxl', $grid-columns);
  @include grid-columns-offset('col-offset-xxl', $grid-columns);
}
