// ==========================================================================
// Twitter Feed
// ==========================================================================

.twitter {
  padding-top: 4rem;
  text-align: center;

  .fa-twitter {
    font-size: 3rem;
    color: #55acee;
  }

  blockquote {
    max-width: 50rem;
    margin: 0 auto;
    font-size: 1.5rem;
    font-weight: 300;
    font-style: italic;

    a {
      color: $brand-color-c;
    }
  }
}
