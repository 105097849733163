// ==========================================================================
// Sections
// ==========================================================================

%gradient {
  @include gradient($white, tint($brand-color-d, 75%));
}

.section {
  @include variant('default') {
    padding: 4rem 0;

    @media #{$medium-up} {
      padding: 6rem 0;
    }
  }

  @include variant('plain-page') {
    padding-top: 3rem;

    @media #{$medium-up} {
      padding-top: 3rem;
    }
  }

  @include variant('shade-light') {
    background-color: tint($brand-color-d, 75%);
  }

  @include has('heading') {
    margin-top: 0;
    font-size: 2.25rem;
    font-weight: 300;

    @media #{$small-only} {
      text-align: center;
    }
  }
}
