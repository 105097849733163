// ==========================================================================
// Buttons
// ==========================================================================

.btn {
  &:focus,
  &:active {
    box-shadow: none;
  }

  .related-content &,
  .related-links & {
    text-align: left;
  }
}

%btn-default {
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 600;
  color: $brand-color-c;
  letter-spacing: .05rem;
  text-transform: uppercase;
}

.btn-link {
  @extend %btn-default;
  padding-left: 0;

  &:after {
    position: relative;
    top: 0;
    display: inline-block;
    padding-left: .5rem;
    content: "\f105";
    font-size: 1rem;
    font-family: FontAwesome;
  }
}

.btn-action {
  padding: 1.25rem 1.75rem;
  border-radius: 3rem;
  background-color: $brand-color-b;
  font-size: .875rem;
  font-weight: 600;
  color: $white;
  letter-spacing: .075rem;
  text-transform: uppercase;
}
