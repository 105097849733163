// ==========================================================================
// Site Navigation
// ==========================================================================

.nav-open {
  overflow: hidden;
}

.nav-holder {
  position: fixed;
  bottom: -100%;
  left: 0;
  z-index: 102;
  width: 100%;
  padding: 2.25rem 0 1.5rem;
  background-color: tint($brand-color-d, 50%);
  transition: bottom .5s ease;

  @media #{$large-up} {
    position: relative;
    top: 0;
    bottom: 0;
    padding: 0;
    background: none;
  }

  .nav-open & {
    bottom: 0;
  }
}

.site-navigation {
  text-align: center;
}

// --------------------------------------------------------------------------
// Site Navigation Links
// --------------------------------------------------------------------------

.site-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  @media #{$large-up} {
    margin-top: 1.375rem;
  }

  @include has('item') {
    @media #{$large-up} {
      display: inline-block;
      padding: 0 1.25rem;
    }

    @media #{$xlarge-up} {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }

  @include has('link') {
    position: relative;
    display: block;
    padding: .25rem 1.5rem;
    font-size: 1.375rem;
    color: tint($brand-color-a, 30%);

    @media #{$large-up} {
      padding: 0;
      font-size: 1rem;

      &:hover:after {
        bottom: -.125rem;
        border-color: tint($brand-color-a, 50%);
      }

      &:after {
        position: absolute;
        bottom: .25rem;
        left: 12.5%;
        width: 75%;
        border-bottom: 1px solid transparent;
        content: '';
        transition: .3s;

        .site-menu__item--active & {
          bottom: -.125rem;
          border-color: $brand-color-b;
        }
      }
    }

    .site-menu__item--active & {
      font-weight: 600;
      color: $brand-color-b;
    }
  }
}
