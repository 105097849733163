// ==========================================================================
// Club Getup: Functions
// ==========================================================================

// --------------------------------------------------------------------------
// Add percentage of white to a colour
// --------------------------------------------------------------------------

@function tint($color, $percent) {
  @return mix(#fff, $color, $percent);
}

// --------------------------------------------------------------------------
// Add percentage of black to a colour
// --------------------------------------------------------------------------

@function shade($color, $percent) {
  @return mix(#000, $color, $percent);
}
