// ==========================================================================
// Forms & Form Fields
// ==========================================================================

.form-group {
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: .125rem;
    font-size: .75rem;
    font-weight: 700;
    letter-spacing: .075rem;
    text-transform: uppercase;
  }
}

.form-control {
  width: 100%;
  padding: 1rem 1.25rem;
  border-width: 0 0 1px;
  border-color: transparent;
  border-radius: 0;
  background-color: $white;
  font-size: 1.25rem;
  outline: none;
  appearance: none;

  &:focus {
    border-color: $brand-color-c;
    transition: .3s;
  }
}

input[type='text'],
input[type='email'],
input[type='tel'],
select {
  &.form-control {
    height: 4rem;

    &:focus {
      border-color: $brand-color-c;
    }
  }
}

.form {
  @media #{$medium-up} {
    margin: 0 4rem;
  }
}

.form-message {
  display: block;
  height: 164px;

  &:focus {
    border-color: $brand-color-c;
  }
}

.form-actions {
  margin-top: 1rem;
}

// --------------------------------------------------------------------------
// Honeypot
// --------------------------------------------------------------------------

[id="your-full-name"] {
  display: none;
}

// --------------------------------------------------------------------------
// Errors
// --------------------------------------------------------------------------

.errors {
  @extend %list-unstyled;
  margin-top: .5rem;
  font-size: .75rem;
  color: $brand-color-b;
  letter-spacing: .125rem;
  text-transform: uppercase;
}

// --------------------------------------------------------------------------
// Notices and Messages
// --------------------------------------------------------------------------

.notice {
  padding: 1rem;
  background-color: $brand-color-b;
  font-size: .75rem;
  color: $white;
  letter-spacing: .125rem;
  text-align: center;
  text-transform: uppercase;

  p {
    margin: 0;
  }
}

// --------------------------------------------------------------------------
// Styled Checkbox
// --------------------------------------------------------------------------

.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    padding: 0;
    cursor: pointer;
  }

  // Box.
  & + label::before {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    content: '';
    background: $white;
    vertical-align: text-top;
    border-radius: .25rem;
  }

  // Box hover
  &:checked + label::before,
  &:hover + label::before {
    background: $brand-color-c;
  }

  // Box focus
  &:focus + label::before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, .05);
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label::before {
    background: #ddd;
    box-shadow: none;
  }

  // Checkmark
  &:checked + label::after {
    position: absolute;
    top: 9px;
    left: 5px;
    width: 2px;
    height: 2px;
    content: '';
    background: $white;
    transform: rotate(45deg);
    box-shadow: 2px 0 0 $white, 4px 0 0 $white, 4px -2px 0 $white, 4px -4px 0 $white, 4px -6px 0 $white, 4px -8px 0 $white;
  }
}
