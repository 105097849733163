// ==========================================================================
// Site Footer
// ==========================================================================

.site-footer {
  padding: 4rem 0;
  color: tint($brand-color-a, 50%);
  text-align: center;

  p {
    margin: 0;
    font-size: .875rem;
    font-weight: 100;
  }
}

// --------------------------------------------------------------------------
// Footer links
// --------------------------------------------------------------------------

.footer-links {
  margin: 0 0 3rem;
  padding: 0;
  list-style: none;

  @include has('item') {
    margin-bottom: .5rem;

    @media #{$medium-up} {
      display: inline-block;
      margin-bottom: 0;
      padding: 0 1rem;
    }

    @media #{$large-up} {
      padding: 0 1.25rem;
    }
  }

  @include has('link') {
    font-size: .875rem;
    color: tint($brand-color-a, 40%);
    letter-spacing: .075rem;
    text-transform: uppercase;
    transition: .5s ease-in-out;

    &:hover {
      color: $brand-color-a;
    }
  }
}
