/* ==========================================================================
 * Style Guide
 * ========================================================================== */

/* --------------------------------------------------------------------------
 * Colour Swatches
 * -------------------------------------------------------------------------- */

#csg-colour-swatch-a { background-color: $brand-color-a }
#csg-colour-swatch-b { background-color: $brand-color-b }
#csg-colour-swatch-c { background-color: $brand-color-c }
#csg-colour-swatch-d { background-color: $brand-color-d }
#csg-colour-swatch-e { background-color: #fff; }
