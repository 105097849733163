// ==========================================================================
// Site Header
// ==========================================================================

.site-header {
  position: relative;
  padding: 1.5rem 0;

  @media #{$medium-up} {
    padding: 2rem 0;
  }

  @media #{$large-up} {
    padding: 2.5rem 0;
  }
}

.site-logo {
  position: relative;
  z-index: 104;
  display: block;
  width: 150px;
  margin: 0 auto;

  @media #{$medium-up} {
    float: left;
  }

  > img {
    display: block;
    width: 100%;
  }
}
