.digital-page {
  overflow: hidden;
  padding: 8rem 0 6rem;
  background: url('../img/digital/bg.jpg') $brand-color-a;
  text-align: center;
}

.digital-logo {
  width: 20rem;
  margin: 0 auto;

  > img {
    display: block;
    width: 100%;
    margin-bottom: 3rem;
  }
}
