// ==========================================================================
// Social Icons
// ==========================================================================

.social-icons {
  margin: 0 0 1rem;
  padding: 0;
  line-height: 1.3;
  list-style: none;
  text-align: center;

  @media #{$large-up} {
    float: right;
    margin: 0;
  }

  @include has('item') {
    display: inline-block;
    padding: 0 .5rem;
  }

  @include has('link') {
    font-size: 1.25rem;
    color: $brand-color-a;
  }
}
