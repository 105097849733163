// ==========================================================================
// Related Links
// ==========================================================================

.related-content {
  @media #{$medium-up} {
    margin: 2rem 0 0;
  }
}

.related-links {
  margin: 0;
  padding: 0;
  list-style: none;

  @include has('item') {
    .related-links--inline & {
      display: inline-block;
    }
  }
}

.related-link-heading {
  font-size: .875rem;
  letter-spacing: .125rem;
  text-transform: uppercase;
}

.related-content-links {
  .btn-link {
    white-space: normal;
    text-align: left;
  }
}
