// ==========================================================================
// Club Getup: Buttons
// ==========================================================================

.btn {
  display: block;
  margin-bottom: 0;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;

  @media #{$medium-up} {
    display: inline-block;
  }

  &:active,
  &.active {
    outline: 0;
  }

  &.disabled,
  &[disabled],
  [disabled] & {
    opacity: .65;
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    box-shadow: none;
  }
}
