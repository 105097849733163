// ==========================================================================
// Work
// ==========================================================================

// --------------------------------------------------------------------------
// Grid
// --------------------------------------------------------------------------

.portfolio-grid {
  @include clearfix;
  padding: 2rem 0;

  @media #{$large-up} {
    padding: 4rem 0;
  }

  @include has('item') {
    margin-bottom: 2%;

    @media #{$medium-up} {
      float: left;
    }

    @media #{$medium-only} {
      width: 49%;
      margin-right: 2%;
      margin-bottom: 2%;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media #{$large-up} {
      width: 32.6666%;
      margin-right: 1%;
      margin-bottom: 1%;

      &:nth-child(1),
      &:nth-child(2) {
        width: 49.5%;
      }

      &:nth-child(2) {
        margin-right: 0;
      }

      &:nth-child(3n + 2) {
        margin-right: 0;
      }
    }
  }
}

// --------------------------------------------------------------------------
// Thumbnails
// --------------------------------------------------------------------------

.portfolio-thumb {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;

  &:hover {
    .portfolio-label {
      bottom: 1rem;
    }
  }

  // @media #{$medium-up} {
  //   margin-bottom: 1%;
  // }
}

// --------------------------------------------------------------------------
// Labels
// --------------------------------------------------------------------------

.portfolio-label {
  position: absolute;
  bottom: -2rem;
  left: 1rem;
  margin: 0;
  padding: .3rem .5rem;
  background-color: $brand-color-b;
  font-size: .75rem;
  font-weight: 500;
  color: $white;
  letter-spacing: .125rem;
  line-height: 1.2;
  text-transform: uppercase;
  transition: .3s;
}
