// ==========================================================================
// Club Getup: Base Styles
// ==========================================================================

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  padding: 0;
}
