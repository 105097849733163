// ==========================================================================
// Sectors
// ==========================================================================

.sector {
  @extend %gradient;
  position: relative;
  display: block;
  overflow: hidden;
  padding: 2rem 0 0;
  text-align: center;

  @include has('heading') {
    margin: 0;
    font-size: 2.25rem;
    line-height: 1;
  }

  @include has('description') {
    max-width: 20rem;
    margin: 1rem auto;
    color: tint($brand-color-a, 50%);

    @media #{$medium-only} {
      margin-right: 0;
      margin-left: 0;
    }

    @media #{$large-up} {
      min-height: 5rem;
    }
  }

  @include has('info') {
    @media #{$medium-only} {
      float: left;
      width: 40%;
      padding-left: 3rem;
      text-align: left;
    }
  }

  @include has('image') {
    position: relative;
    left: 0;
    width: 100%;
    margin-top: 4rem;
    text-align: center;
    transition: .4s ease;

    @media #{$medium-only} {
      float: right;
      width: 60%;
      margin-top: 0;
    }

    @media #{$large-up} {
      bottom: -.25rem;

      .sector:hover & {
        bottom: 0;
        transition: .3s ease-in-out;
      }
    }

    > img {
      display: block;
      width: 100%;
    }
  }
}

@media #{$large-up} {
  .sectors-holder {
    .owl-wrapper .owl-item:nth-child(2) {
      margin: 0 .25rem;
    }
  }
}
