// ==========================================================================
// Matrix
// ==========================================================================

.block-list-group {
  @media #{$medium-up} {
    display: flex;
    flex-wrap: wrap;

    &__item--full {
      width: 100%;
    }

    &__item--half {
      width: 50%;
    }

    &__item--third {
      width: 33%;
    }
  }
}

.block-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;

  @media #{$medium-up} {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &__item {
      .block-list-group__item--full & {
        width: 33%;
        padding-right: .3333%;
      }
    }
  }
}

.matrix {
  &:nth-child(2n) {
    background-color: tint($brand-color-d, 50%);
  }

  .service-page &:nth-child(2) {
    background: none;
  }
}

.matrix-split {
  @include clearfix;

  @include has('img') {
    text-align: center;

    .split-slideshow {
      max-width: 30rem;
      margin-right: auto;
      margin-left: auto;
    }

    @media #{$small-only} {
      margin-bottom: 2rem;
    }

    @media #{$medium-up} {
      float: left;
      width: 47%;

      .matrix-split--img-right & {
        float: right;
      }
    }

    @media #{$large-up} {
      width: 48%;
    }
  }

  @include has('copy') {
    @media #{$medium-up} {
      float: right;
      width: 47%;
    }

    @media #{$large-up} {
      width: 48%;
    }

    .matrix-split--img-right & {
      float: left;
    }
  }
}

.matrix-default {
  padding: 4rem 0;

  @media #{$medium-up} {
    padding: 6rem 0;
  }
}

.matrix-image-single {
  padding: 5rem 0;

  .thumbnail {
    max-width: 50rem;
    margin-right: auto;
    margin-left: auto;
    padding: 0 1rem;
  }
}

.matrix-visual-quote {
  position: relative;
  margin: .25rem 0;
  padding: 3rem 0;
  background-position: center;
  background-size: cover;

  @media #{$small-only} {
    text-align: center;
  }

  // blockquote {
  //   margin: 0;

  //   p {
  //     margin-top: 0;
  //   }
  // }
}

.featured-quote {
  margin: 0;

  @media #{$medium-only} {
    padding-top: 30%;
  }

  @include variant('left') {
    @media #{$large-up} {
      padding-right: 50%;
    }
  }

  @include variant('right') {
    @media #{$large-up} {
      padding-left: 50%;
    }
  }
}

.matrix-quote-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .9;
}

.matrix-gallery-slideshow {
  overflow: hidden;
  margin: .25rem 0;
}

.matrix-quote-overlay {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ5JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjY1Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 49%, rgba(0,0,0,1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(49%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,1)));
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 49%,rgba(0,0,0,1) 100%);
  background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 49%,rgba(0,0,0,1) 100%);
  background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 49%,rgba(0,0,0,1) 100%);
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 49%,rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
  opacity: .9;

  @media #{$medium-only} {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ5JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjY1Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 49%, rgba(0,0,0,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(49%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,1)));
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 49%,rgba(0,0,0,1) 100%);
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 49%,rgba(0,0,0,1) 100%);
    background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 49%,rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 49%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
    opacity: .9;
  }

  @media #{$large-up} {
    .matrix-visual-quote--right & {
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjU1JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjY1Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjgiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
      background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.8) 80%);
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(0,0,0,0)), color-stop(50%, rgba(0,0,0,0.65)), color-stop(80%,rgba(0,0,0,0.8)));
      background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.8) 80%);
      background: -o-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.8) 80%);
      background: -ms-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.8) 80%);
      background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0.8) 80%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=1);
    }

    .matrix-visual-quote--left & {
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIyMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC44Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjY1Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
      background: -moz-linear-gradient(left,  rgba(0,0,0,0.8) 20%, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0) 100%);
      background: -webkit-gradient(linear, left top, right top, color-stop(20%,rgba(0,0,0,0.8)), color-stop(50%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,0)));
      background: -webkit-linear-gradient(left,  rgba(0,0,0,0.8) 20%,rgba(0,0,0,0.65) 50%,rgba(0,0,0,0) 100%);
      background: -o-linear-gradient(left,  rgba(0,0,0,0.8) 20%,rgba(0,0,0,0.65) 50%,rgba(0,0,0,0) 100%);
      background: -ms-linear-gradient(left,  rgba(0,0,0,0.8) 20%,rgba(0,0,0,0.65) 50%,rgba(0,0,0,0) 100%);
      background: linear-gradient(to right,  rgba(0,0,0,0.8) 20%,rgba(0,0,0,0.65) 50%,rgba(0,0,0,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#00000000',GradientType=1 );
    }
  }
}

/* ==========================================================================
 * Matrix (Tablet)
 * ========================================================================== */

@media #{$medium-up} {
  .matrix-split-text {
    .col-md-6 {
      &:first-child {
        p {
          max-width: 95%;
        }
      }

      &:last-child {
        p {
          margin-left: 5%;
        }
      }
    }
  }

  .matrix-gallery-slideshow {
    padding: 0 .125rem;

    .owl-wrapper {
      .owl-item {
        padding-left: .125rem;
        padding-right: .125rem;
      }
    }
  }

  .visual-quote {
     margin-left: .25rem;
     margin-right: .25rem;
  }
}

/* ==========================================================================
 * Matrix (Desktop)
 * ========================================================================== */

@media #{$large-up} {
  .matrix-visual-quote {
    padding: 8rem 0;

    blockquote {
      p {
        font-size: 1.75rem;
      }
    }
  }

  .matrix-visual-quote-center {
    .matrix-quote-overlay {
      background: #000;
      opacity: .6;
    }
  }

  .split-text {
    max-width: 90%;
    margin: 5% 0;
  }
}
