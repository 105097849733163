// ==========================================================================
// Entry Breadcrumb
// ==========================================================================

.entry-breadcrumb {
  display: inline-block;
  margin-bottom: 1rem;
  padding: .5rem .5rem .5rem .75rem;
  background-color: $brand-color-b;
  font-size: .675rem;
  font-weight: 500;
  color: rgba(255, 255, 255, .75);
  letter-spacing: .05rem;
  line-height: 1;
}

.entry-breadcrumb-links {
  margin: 0;
  padding: 0;
  list-style: none;

  @include has('item') {
    display: inline-block;
    padding-right: .25rem;

    &:not(:last-child) {
      &:after {
        padding-left: .25rem;
        content: "\f105";
        font-family: FontAwesome;
      }
    }
  }

  @include has('link') {
    color: $white;
    text-transform: uppercase;
  }
}
