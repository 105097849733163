// ==========================================================================
// Configuration
// ==========================================================================

// --------------------------------------------------------------------------
// Typography
// --------------------------------------------------------------------------

$font-family-sans: "Open Sans", Helvetica, Arial, sans-serif;
$font-family-serif: "Open Sans", Helvetica, Arial, sans-serif;
$font-family-headings: canada-type-gibson, Gibson, Helvetica, Arial, sans-serif;
$line-height: 1.7;

// --------------------------------------------------------------------------
// Colours
// --------------------------------------------------------------------------

$brand-color-a: #0f284b;
$brand-color-b: #d51e47;
$brand-color-c: #29abe2;
$brand-color-d: #e6e7d4;

// --------------------------------------------------------------------------
// Media Query Breakpoints
// --------------------------------------------------------------------------

$small-min:   0;
$small-max:   640px;
$medium-min:  641px;
$medium-max:  1023px;
$large-min:   1024px;
$large-max:   1400px;
$xlarge-min:  1401px;
$xlarge-max:  1401px;
$xxlarge-min: 1401px;
$header-min: 640px;
$header-max: 1280px;
