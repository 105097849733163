// ==========================================================================
// Production
// ==========================================================================

.productions-list {
  @include clearfix;
  margin: 0;
  padding: 0;
  list-style: none;
}

.production-card {
  margin-bottom: .7rem;
  background-color: $white;

  .btn-link {
    margin-top: 1rem;
    padding: 0;
  }
}

.card-image {
  background-color: $brand-color-d;

  > img {
    display: block;
    width: 100%;
  }
}

.card-details {
  padding: 1.5rem;

  h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
  }

  small {
    font-style: italic;
  }

  p {
    font-size: .875rem;
  }

  .btn {
    font-size: .75rem;
  }
}

.card-truncate {
  overflow: hidden;
  height: 6rem;
  border-bottom: 1px solid tint($brand-color-d, 50%);

  @media #{$medium-up} {
    height: 10rem;
  }
}

.productions-list {
  > li {
    @media #{$medium-up} {
      float: left;
    }

    @media #{$medium-only} {
      width: 49%;
      margin-right: 2%;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media #{$large-up} {
      float: left;
      width: 32.66666666%;
      margin-right: 1%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
