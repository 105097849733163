// ==========================================================================
// Company History
// ==========================================================================

.company-history-heading {
  margin: 0 0 2rem;
  font-size: 1rem;
  color: $brand-color-c;
  letter-spacing: .125rem;
  text-transform: uppercase;
}

.history-row {
  position: relative;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $brand-color-d;
  font-size: .875rem;

  strong {
    font-weight: 600;
    color: $brand-color-a;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}
