/* ==========================================================================
 * Mixins
 * ========================================================================== */

// --------------------------------------------------------------------------
// Element
// --------------------------------------------------------------------------

@mixin has($name) {
  @at-root #{&}__#{$name} {
    @content
  }
}

// --------------------------------------------------------------------------
// Modifier
// --------------------------------------------------------------------------

@mixin variant($name) {
  @at-root #{&}--#{$name} {
    @content
  }
}

@mixin gradient($start, $end) {
    background: $end;
    background: -moz-linear-gradient(top, $start 0%, $end 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start), color-stop(100%, $end));
    background: -webkit-linear-gradient(top, $start 0%, $end 100%);
    background: -o-linear-gradient(top, $start 0%, $end 100%);
    background: -ms-linear-gradient(top, $start 0%, $end 100%);
    background: linear-gradient(to bottom, $start 0%, $end 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start}', endColorstr='#{$end}', GradientType=0);
}
