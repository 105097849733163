// ==========================================================================
// Case Studies
// ==========================================================================

.case-study {
  position: relative;
}

.case-study-hero {
  margin: 0;

  > img {
    display: block;
    width: 100%;
  }
}

.case-study-intro {
  margin-top: 0;
  padding-bottom: 3rem;

  @include variant('dark') {
    padding: 4rem 0 3rem;
    background-color: $brand-color-a;
  }
}

.case-study-heading {
  margin: 0;
  font-size: 2.5rem;
  color: $white;
}

.case-study-body {
  @media #{$large-up} {
    padding-left: 2rem;
  }
}

.related-link-heading {
  .case-study-intro--dark & {
    font-weight: 500;
    color: $white;
  }
}
