// ==========================================================================
// Headline
// ==========================================================================

.headline-content {
  position: relative;
}

.headline-header {
  position: relative;
  padding: 4rem 0 3rem;
  text-align: center;

  h1,
  p {
    margin-right: auto;
    margin-left: auto;
  }

  h1 {
    max-width: 40rem;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.1;
  }

  p {
    max-width: 33rem;
  }
}

/* ==========================================================================
 * Headline (Tablet)
 * ========================================================================== */

@media #{$medium-up} {
  .headline-header {
    padding: 3rem 0;

    h1 {
      font-size: 2.5rem;
    }
  }
}
