// ==========================================================================
// Archive
// ==========================================================================

// --------------------------------------------------------------------------
// Archive Alphabet
// --------------------------------------------------------------------------

.alphabet {
  @include clearfix;
  max-width: 60rem;
  margin: 2rem auto;
  padding: 0;
  list-style: none;
  text-align: center;

  @include has('item') {
    display: inline-block;
    margin: .375rem .125rem;
  }
}

.alphabet-letter {
  position: relative;
  display: block;
  width: 4.25rem;
  height: 4.25rem;
  border: .25rem solid $white;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: 1.5rem;
  font-weight: 600;
  color: $brand-color-a;
  line-height: 3.75rem;
  cursor: default;

  @media #{$medium-up} {
    width: 5.25rem;
    height: 5.25rem;
    line-height: 4.75rem;
  }

  @include variant('active') {
    &:hover {
      border-color: $brand-color-c;
      cursor: pointer;
    }
  }

  @include has('overlay') {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $brand-color-a;
    opacity: .25;
    transition: .5s;

    .alphabet-letter:hover & {
      opacity: .125;
    }
  }

  @include has('char') {
    position: relative;
    z-index: 10;
    color: tint($brand-color-a, 75%);

    .alphabet-letter--active:hover & {
      color: $brand-color-c;
    }

    .alphabet-letter--photo:hover & {
      color: $white;
    }

    .alphabet-letter--active & {
      color: $white;
    }
  }
}

// --------------------------------------------------------------------------
// Archive Entries
// --------------------------------------------------------------------------

.archive-entry {
  @include clearfix;
  padding: 4rem 0;

  @include variant('alt') {
    background-color: tint($brand-color-d, 80%);
  }

  @include has('image') {
    @media #{$medium-up} {
      float: left;
      width: 40%;
    }
  }

  @include has('details') {
    @media #{$medium-up} {
      float: right;
      width: 60%;
      padding-left: 2rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  @include has('heading') {
    margin-bottom: 0;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.2;

    @media #{$medium-up} {
      margin: 0;
    }
  }
}

.archive-gallery {
  @include clearfix;
  margin: .275rem 0 0;
  padding: 0;
  list-style: none;

  > li {
    float: left;
    width: 32.3333%;
    margin-right: 1%;
    margin-bottom: 1%;

    img {
      display: block;
      width: 100%;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    @media #{$large-up} {
      width: 24.25%;

      &:nth-child(2n),
      &:nth-child(3n) {
        margin-right: 1%;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
