// ==========================================================================
// Hamburger
// ==========================================================================

.hamburger {
  position: absolute;
  top: 3rem;
  right: 1.5rem;
  z-index: 103;
  display: block;
  width: 1.5rem;
  cursor: pointer;

  @media #{$medium-up} {
    top: 3.75rem;
    right: 3rem;
  }

  @media #{$large-up} {
    display: none;
  }

  @include has('bar') {
    position: relative;
    display: block;
    height: 3px;
    margin-bottom: .25rem;
    background-color: $brand-color-a;
    transition: .3s;

    &:last-child {
      margin: 0;
    }

    .hamburger--active & {
      &:first-child {
        position: relative;
        top: 7px;
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        top: -7px;
        transform: rotate(45deg);
      }
    }
  }
}
