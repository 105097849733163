// ==========================================================================
// Club Getup: Utility Classes
// ==========================================================================

// --------------------------------------------------------------------------
// Text Alignment
// --------------------------------------------------------------------------

.text-left   { text-align: left; }
.text-center { text-align: center; }
.text-right  { text-align: right; }

// --------------------------------------------------------------------------
// Floats
// --------------------------------------------------------------------------

.pull-right { float: right; }
.pull-left  { float: left; }

// --------------------------------------------------------------------------
// Clearfix
// --------------------------------------------------------------------------

.clearfix { @include clearfix }
