.cc-banner {
  font-family: $font-family-sans;
}

/* --------------------------------------------------------------------------
 * Contact Information
 * -------------------------------------------------------------------------- */

.contact-info {
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;

  @include has('item') {
    padding: 0 1rem;
    color: $brand-color-c;

    @media #{$medium-up} {
      display: inline-block;
    }
  }

  @include has('link') {
    color: $brand-color-c;

    @media #{$medium-up} {
      font-size: 1.125rem;
    }
  }

  > li {
    .fa {
      position: relative;
      top: .125rem;
      margin-right: .25rem;
      font-size: 1.5rem;
    }

    .fa-phone {
      top: .25rem;
    }
  }
}

.video-inline {
  margin: 2rem auto;

  figcaption {
    margin-top: 1rem;
    font-weight: 300;
    font-style: italic;
  }
}

.fluid-width-video-wrapper {
  margin-right: auto;
  margin-left: auto;

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}
